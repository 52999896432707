@import '../../AppFormStyles.styl'

.plan_info_wrapper
    width: 100%
    display: flex
    flex-direction: column

    .sub_info_title
        font-size: 12px
        line-height: 16px
        font-weight: 500
        letter-spacing: 1.8px
        text-transform: uppercase
        margin-bottom: 8px

    .plan_title
        @extends .modal_title
        margin: 0

    .expiration_date
        font-size: 12px
        line-height: 16px
        letter-spacing: 0.4px
        margin-bottom: 8px
    
    .license_key
        font-size: 12px
        line-height: 16px
        font-weight: 500
        letter-spacing: 1.5px
        margin-bottom: 24px
        text-transform: uppercase

.plan_items_wrapper
    margin-top: 1em

[data-mode="bright"]
    .plan_info_wrapper

        .sub_info_title,
        .plan_title,
        .expiration_date,
        .license_key
            color: colors.grey
        .plan_title
            color: colors.primary_bright

