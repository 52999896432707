@import '../Shared/shared.styl'

.app_wrapper_basic
    display: flex
    flex-direction: column
    align-items: center
    overflow: auto
    height: 100%

.app_wrapper
    @extend .app_wrapper_basic
    padding: 16px

.inner_frame_basic
    position relative
    width: $modalWidth
    max-width: 100%
    min-width: 240px
    min-height: fit-content
    padding: 32px
    border-radius: 4px
    display: flex
    flex-direction: column
    align-items: center

.inner_frame
    @extend .inner_frame_basic
    @extend .block_shadow

[data-mode='dark']
    .inner_frame
        background: colors.overlay_color

.form
    width: 100%

.form_item
    padding-bottom: 4px
    &:last-child
        padding-bottom: 0

.inline_container
    display: flex
    justify-content: space-between

    > div
        flex: 1

    @media (max-width: breakPoints.screenSm - 1px)
        flex-direction: column

.inline_space
    flex: 0 0 auto!important
    width: 20px

.loading_wrapper
    margin-top: 50px


[data-mode] .form_title
    @extend .modal_title
    margin-bottom: 1.5em
    text-align center

.form_section
    width: $modalWidth
    max-width: calc(100% - 32px)

.form_scrollable_area
    width: 100%
    height: 1px
    flex-grow: 1
    overflow: auto
    display: flex
    flex-direction: column
    align-items: center
    > :last-child:after
        content: ""
        display: block
        height: 24px

.actions_area
    width: 100%
    box-shadow: inset 0 1px 0 colors.tertiary_grey
    display: flex
    align-items: center
    justify-content: center
    flex-shrink: 0

.button_wrapper_container
    @extends .center
    width: 100%

.button_wrapper
    width: $modalWidth
    max-width: calc(100% - 32px)
    display: flex
    padding-top: 1em
    justify-content: space-between
    :only-child
        margin-left: auto