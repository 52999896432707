@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;1,300&display=swap');

fonts = json('../../styles/fonts.json', { hash: true })
colors = json('../../styles/colors.json', { hash: true })
breakPoints = json('../../styles/breakPoints.json', { hash: true })

$modalWidth = 512px

.center
    display: flex
    align-items: center
    justify-content: center

.hide_scrollbar
    &::-webkit-scrollbar
        display: none

.block_shadow
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15)

[data-mode] .modal_title,
.modal_title
    font-size: 20px
    line-height: 24px

[data-mode] button.button_style,
.button_style
    font-weight: 500
    letter-spacing: 0.2px

.modal_title1
    font-size: 24px
    font-weight: 400
    line-height: 36px

.subtitle1
    font-size: 16px
    line-height: 24px

.subtitle2
    font-size: 20px
    line-height: 32px

.subtitle3
    font-size: 14px
    line-height: 20px
    font-weight: 400

.button_text
    font-weight: 500
    font-size: 14px
    line-height: 24px

.danger_alert
    background: colors.red_1 !important
    margin-bottom: 24px !important
    border-color: colors.red_2 !important
    color: white
    span[aria-label="close"]
    span[aria-label="close"]:hover
           color: white

.hidden_form
    position: absolute
    z-index: -100
    top: 0
    left: 0
    opacity: 0.5
    pointer-events: none